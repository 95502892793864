import { motion, useAnimation, useInView } from 'framer-motion';
import { useState, useEffect, useRef } from 'react';
import { Hero } from './Hero';
import { UserCards } from './UserCards';
import { Section } from './Section';
import { Carousel } from './Carousel';
import AuthDialog from '../../components/AuthDialog';
import ScrollToTop from '../../components/ScrollToTop';
import { smoothScrollTo } from '../../components/utils/smoothScroll';
import { Helmet } from 'react-helmet';
import IndustriesSection from './Industries';

const dashboardImg = require('../../assets/dashboard_wireframe.svg').default;
const dashboardMobileImg = require('../../assets/dashboard_wireframe_mobile.svg').default;
const shopImg = require('../../assets/shop_wireframe.svg').default;
const shopMobileImg = require('../../assets/shop_wireframe_mobile.svg').default;
const serviceImg = require('../../assets/service_wireframe.svg').default;
const serviceMobileImg = require('../../assets/service_wireframe_mobile.svg').default;
const analyticsImg = require('../../assets/analytics_wireframe.svg').default;
const analyticsMobileImg = require('../../assets/analytics_wireframe_mobile.svg').default;
const chatImg = require('../../assets/chat_wireframe.svg').default;
const chatMobileImg = require('../../assets/chat_wireframe_mobile.svg').default;

const getImageSrc = (largeImage: ImageData, smallImage: ImageData) => {
    return window.innerWidth < 640 ? smallImage : largeImage;
};

export default function Home() {
    const ctaControls = useAnimation();
    const indicatorControls = useAnimation();
    const trackControls = useAnimation();
    const [hasScrolled, setHasScrolled] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dashboardImgSrc, setDashboardImgSrc] = useState(dashboardImg);
    const [shopImgSrc, setShopImgSrc] = useState(shopImg);
    const [serviceImgSrc, setServiceImgSrc] = useState(serviceImg);
    const [analyticsImgSrc, setAnalyticsImgSrc] = useState(analyticsImg);
    const [chatImgSrc, setChatImgSrc] = useState(chatImg);

    const sectionRef = useRef(null);
    const sectionRef0 = useRef(null);
    const sectionRef1 = useRef(null);
    const sectionRef2 = useRef(null);
    const sectionRef3 = useRef(null);
    const sectionRef4 = useRef(null);
    const sectionRef5 = useRef(null);

    const inView0 = useInView(sectionRef0, { once: true });
    const inView1 = useInView(sectionRef1, { once: true });
    const inView2 = useInView(sectionRef2, { once: true });
    const inView3 = useInView(sectionRef3, { once: true });
    const inView4 = useInView(sectionRef4, { once: true });
    const inView5 = useInView(sectionRef5, { once: true });

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleHover = (e: React.MouseEvent<HTMLDivElement>) => {
        const container = document.getElementById('card-container');
        if (container && !isAnimating) {
            const maxScroll = container.scrollWidth - container.clientWidth;
            const currentPosition = e.clientX;
            const screenWidth = window.innerWidth;
            const newPosition = currentPosition < screenWidth / 2 ? 0 : -maxScroll;

            setIsAnimating(true);
            trackControls.start({
                x: newPosition,
                transition: {
                    duration: 1,
                    onComplete: () => setIsAnimating(false)
                }
            });
        }
    };

    useEffect(() => {
        const updateImageSrc = () => {
            setDashboardImgSrc(getImageSrc(dashboardImg, dashboardMobileImg));
            setShopImgSrc(getImageSrc(shopImg, shopMobileImg));
            setServiceImgSrc(getImageSrc(serviceImg, serviceMobileImg));
            setAnalyticsImgSrc(getImageSrc(analyticsImg, analyticsMobileImg));
            setChatImgSrc(getImageSrc(chatImg, chatMobileImg));
        };

        // Update image source on mount and on resize
        updateImageSrc();
        window.addEventListener('resize', updateImageSrc);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('resize', updateImageSrc);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                indicatorControls.start({ opacity: 1, transition: { duration: 0.1 } });
            } else {
                indicatorControls.start({ opacity: 0, transition: { duration: 0.1 } });
            }

            if (window.scrollY === 0 && hasScrolled) {
                setHasScrolled(false);
                ctaControls.start({ y: 0, transition: { duration: 1 } });
            } else if (!hasScrolled && window.scrollY > 0 && window.scrollY < 100) {
                setHasScrolled(true);
                ctaControls.start({ y: '30vh', transition: { duration: 1.66 } });

                document.body.style.overflow = 'hidden';

                setTimeout(() => {
                    if (sectionRef.current) {
                        const offsetBottom = (sectionRef.current as HTMLElement).offsetTop + (sectionRef.current as HTMLElement).offsetHeight;
                        smoothScrollTo(0, offsetBottom - window.innerHeight, 2000);

                        setTimeout(() => {
                            document.body.style.overflow = 'auto';
                        }, 2500);
                    }
                }, 0);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [hasScrolled, ctaControls, indicatorControls]);

    // // Breadcrumb schema
    // const breadcrumbSchema = {
    //     "@context": "https://schema.org",
    //     "@type": "BreadcrumbList",
    //     "itemListElement": [
    //         {
    //             "@type": "ListItem",
    //             "position": 1,
    //             "name": "Home",
    //             "item": "https://www.kloutgroup.com/"
    //         },
    //         {
    //             "@type": "ListItem",
    //             "position": 2,
    //             "name": "General Liability",
    //             "item": "https://www.kloutgroup.com/"
    //         }
    //     ]
    // };

    return (
        <>
            <Helmet>
                <title>Home - Klout</title>
                <meta name="description" content="The first insurtech platform which allows users to &quot;click, quote, bind, renew &amp; manage&quot; all their policies in a central platform." />
                {/* <script type="application/ld+json">{JSON.stringify(breadcrumbSchema)}</script> */}
            </Helmet>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            >

                <motion.div
                    initial={{ opacity: 0, y: 0 }}
                    animate={inView0 ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 1, ease: 'easeOut' }}
                    className='bg-hero_bg bg-cover bg-center'
                >
                    <Hero
                        handleOpenDialog={handleOpenDialog}
                        ctaControls={ctaControls}
                        indicatorControls={indicatorControls}
                        sectionRef={sectionRef0}
                        inView={inView0}
                    />
                    <UserCards
                        handleHover={handleHover}
                        trackControls={trackControls}
                        sectionRef={sectionRef}
                    />
                </motion.div>
                <section className="mt-20 flex flex-col items-center justify-center overflow-x-hidden rounded-md border-y py-4 md:py-8">
                    <motion.h2
                        className='text-2xl text-neutral-600 md:text-4xl md:font-bold'
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, ease: 'easeOut' }}
                    >
                        As seen on
                    </motion.h2>
                    <Carousel />
                </section>
                <div className='bg-grid_two bg-right bg-no-repeat'>
                    <Section
                        className="mt-[80px] flex flex-col-reverse items-center justify-center overflow-x-hidden rounded-md sm:mt-[160px] sm:flex-row"
                        imgSrc={dashboardImgSrc}
                        imgAlt="Dashboard Wireframe"
                        titleText={[
                            "Manage your insurance;",
                            "All in one place"
                        ]}
                        contentText={[
                            "Insurance is too complicated.",
                            "Dramatically improve the management,",
                            "purchasing & servicing of all your policies",
                            "on a centralized hub."
                        ]}
                        sectionRef={sectionRef1}
                        inView={inView1}
                        isTitleRightAligned={true}
                    />
                    <Section
                        className='mt-[80px] flex flex-col-reverse items-center justify-between sm:mt-[160px] sm:flex-row-reverse'
                        imgSrc={shopImgSrc}
                        imgAlt="Shop Wireframe"
                        titleText={["Shop"]}
                        contentText={[
                            "Whether you're renewing or comparing new quotes,",
                            "Ari helps provide side by side visual comparisons"
                        ]}
                        sectionRef={sectionRef2}
                        inView={inView2}
                        isTitleRightAligned={false}
                    />
                </div>
                <div className='bg-grid_one bg-no-repeat sm:bg-left'>
                    <Section
                        className="mt-[80px] flex flex-col-reverse items-center justify-center overflow-x-hidden rounded-md sm:mt-[160px] sm:flex-row"
                        imgSrc={serviceImgSrc}
                        imgAlt="Service Wireframe"
                        titleText={["Service"]}
                        contentText={[
                            "24/7 Access to all your policy documents",
                            "(in case you get pulled over) &",
                            "a self service certificate of insurance",
                            "to send your vendors certificates in minutes"
                        ]}
                        sectionRef={sectionRef3}
                        inView={inView3}
                        isTitleRightAligned={true}
                    />
                    <Section
                        className='mt-[80px] flex flex-col-reverse items-center justify-between sm:mt-[160px] sm:flex-row-reverse'
                        imgSrc={analyticsImgSrc}
                        imgAlt="Analytics Wireframe"
                        titleText={["Analytics"]}
                        contentText={[
                            "Never lose track of your bills again.",
                            "Track & view all of your payments year over year",
                            "to accurately compare any variances"
                        ]}
                        sectionRef={sectionRef4}
                        inView={inView4}
                        isTitleRightAligned={false}
                    />
                    <Section
                        className="mt-[80px] flex flex-col-reverse items-center justify-center overflow-x-hidden rounded-md sm:mt-[160px] sm:flex-row"
                        imgSrc={chatImgSrc}
                        imgAlt="Chat Wireframe"
                        titleText={["Support"]}
                        contentText={[
                            "Chat/Text, Email, & Schedule a call",
                            "directly with your team assigned to your account.",
                            "Gone are the days you call",
                            "a 1-800 number and wait on hold!"
                        ]}
                        sectionRef={sectionRef5}
                        inView={inView5}
                        isTitleRightAligned={true}
                    />
                </div>
                <section className='my-[80px] flex items-center justify-center bg-white px-4 sm:p-10'>
                    <div className='flex flex-col items-center overflow-hidden rounded-2xl border p-4 shadow-lg sm:p-10'>
                        <img
                            src={require('../../assets/gauge.svg').default}
                            alt="Business Insider Logo"
                            className='w-10'
                        />
                        <h1 className="mt-4 text-center text-2xl sm:text-5xl">
                            Insurance for the modern day in age.
                        </h1>
                        <div className='mt-[70px] flex flex-col justify-between space-y-8 sm:flex-row sm:space-y-0'>
                            <Card
                                title='ARI'
                                content='Ari is the name of our AI, he helps answer questions, aides in basic service requests and enhances your customer experience'
                            />
                            <Card
                                title='Track & Manage'
                                content='Tired of all the insurance paperwork in the mail? So are we, which is why we add all of your policy documents by policy type'
                            />
                            <Card
                                title='24/7 Access'
                                content='Access all of your insurance, regardless of carrier all on one platform. This helps our customers consolidate and manage all of their policies instead of juggling multiple portals'
                            />
                        </div>
                    </div>
                </section>
                <IndustriesSection />
                <AuthDialog isOpen={isDialogOpen} onClose={handleCloseDialog} />
                <ScrollToTop />
            </motion.div>
        </>
    );
}

interface CardProps {
    title: string;
    content: string;
}

const Card: React.FC<CardProps> = ({ title, content }) => {
    return <div className="flex max-w-[400px] flex-1 flex-col justify-start rounded-xl border border-neutral-200 px-4 py-6 sm:mx-4 sm:px-8 sm:pb-12">
        <h4 className="mb-4 text-center text-xl">{title}</h4>
        {Array.isArray(content) ? (
            <ul className="list-disc pl-5 text-neutral-400">
                {content.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        ) : (
            <p className="text-center text-neutral-400 sm:text-left">{content}</p>
        )}
    </div>;
}
