import { AnimationControls, motion } from 'framer-motion';
import { RefObject } from 'react';

const ARROW_ICON_SRC = require('../../assets/icons/arrow-up-right.svg').default;

interface HeroProps {
    handleOpenDialog: () => void;
    ctaControls: AnimationControls;
    indicatorControls: AnimationControls;
    sectionRef: RefObject<HTMLElement>;
    inView: boolean;
}

export const Hero: React.FC<HeroProps> = ({ handleOpenDialog, ctaControls, indicatorControls, sectionRef, inView }) => {
    return (
        <motion.section
            ref={sectionRef}
            animate={ctaControls}
            initial={{ y: 0 }}
            className="flex min-h-screen flex-col items-center justify-center space-y-4 px-8 text-center"
        >
            <motion.div
                className="flex min-h-screen flex-col items-center justify-center space-y-4 px-8 text-center"
                initial={{ opacity: 0, y: 20 }}
                animate={inView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1, ease: 'easeOut' }}
            >
                <h1 className="text-3xl text-neutral-900 md:text-5xl">
                    "Insurance for the Modern Age"
                </h1>
                <p className="text-[15px] font-[400] text-secondary-light md:text-2xl">
                    Shop, Purchase, Manage, Consolidate All your Insurance, All in one place
                </p>
                <button
                    onClick={handleOpenDialog}
                    className="flex w-fit items-center rounded-md bg-neutral-900 px-4 py-2 text-white md:text-xl"
                >
                    Request access
                    <img src={ARROW_ICON_SRC} alt="Arrow Icon" className="ml-2 h-[20px] md:h-[26px]" />
                </button>
                <motion.div
                    className="absolute bottom-5 left-1/2 flex -translate-x-1/2 transform flex-col items-center text-lg text-black transition-opacity hover:opacity-50"
                    initial={{ opacity: 1 }}
                    animate={indicatorControls}
                >
                    <div className="relative flex h-10 w-6 items-center justify-center rounded-full border-2 border-black">
                        <div className="animate-dot h-2 w-2 rounded-full bg-black"></div>
                    </div>
                </motion.div>
            </motion.div>
        </motion.section>
    );
};
