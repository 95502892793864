import React, { useState } from 'react';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';

interface MyFormData {
    full_name: string;
    email: string;
    phone: string;
}

interface AuthDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const AuthDialog: React.FC<AuthDialogProps> = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState<MyFormData>({
        full_name: '',
        email: '',
        phone: '',
    });
    const [status, setStatus] = useState('')

    if (!isOpen) return null;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setStatus('Sending');

        const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID!;
        const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID!;
        const userId = process.env.REACT_APP_EMAILJS_KEY!;

        emailjs.sendForm(serviceId, templateId, e.currentTarget, userId)
            .then((result: { text: any; }) => {
                console.log(result.text);
                alert('Email sent successfully!');
                setStatus('');
            }, (error: { text: any; }) => {
                console.log(error.text);
                alert('Failed to send email.');
                setStatus('');
            });

        onClose();
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                className="relative flex w-11/12 flex-col items-center rounded-lg bg-white p-8 text-center shadow-lg md:w-1/2 lg:w-1/3"
            >
                <button
                    onClick={onClose}
                    className="absolute right-6 top-4 text-4xl font-bold text-gray-600"
                >
                    &times;
                </button>
                <h2 className="mb-2 text-2xl md:mb-4 md:text-4xl">
                    Join Our Waitlist:
                    <br />
                    Be the First to Know!
                </h2>
                <p className="mb-6 w-11/12">
                    Sign up now to receive the latest updates about our revolutionary insurance platform. Enter your email, phone number, and name to be notified as soon as we launch. Don’t miss out on the opportunity to experience cutting-edge technology that will transform how you manage, purchase, and compare insurance. Be part of the future of insurance with Klout!
                </p>
                <form className="mt-2 w-2/3" onSubmit={handleSubmit}>
                    <div className='my-4'>
                        <input
                            type="text"
                            name="full_name"
                            placeholder="Full Name"
                            value={formData.full_name}
                            onChange={handleChange}
                            className="w-full rounded-lg border px-4 py-2"
                            required
                        />
                    </div>
                    <div className='my-4'>
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full rounded-lg border px-4 py-2"
                            required
                        />
                    </div>
                    <div className='my-4'>
                        <input
                            type="tel"
                            name="phone"
                            placeholder="Phone Number"
                            value={formData.phone}
                            onChange={handleChange}
                            className="w-full rounded-lg border px-4 py-2"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="my-6 w-fit rounded-lg bg-black px-20 py-2 text-white"
                    >
                        {status ? 'Submiting...' : 'Sign Up'}
                    </button>
                </form>
            </motion.div>
        </div>
    );
};

export default AuthDialog;
