import React from 'react';
import { Link } from 'react-router-dom';

const LOGO_SRC = require('../assets/klout_white_logo.svg').default;

interface LinkItemProps {
    text: string;
    link: string;
}

interface FooterSectionProps {
    title: string;
    links: LinkItemProps[];
}

const Footer: React.FC = () => {
    return (
        <footer className="bg-black text-gray-300">
            <div className="flex w-full flex-col items-center justify-center">
                <div className="container flex w-full flex-wrap justify-between px-10 pt-4 sm:w-3/4 sm:py-4">
                    <FooterSection title="Products" links={productLinks} />
                    <FooterSection title="Industries" links={industryLinks} />
                    <FooterSection title="Company" links={companyLinks} />
                </div>
                <div className="flex w-full flex-col items-center justify-between space-y-4 border-t border-gray-700 py-4 sm:flex-row sm:space-y-0 sm:px-24">
                    <img src={LOGO_SRC} alt="Logo" />
                    <div>© 2024 Klout. All rights reserved.</div>
                </div>
            </div>
        </footer>
    );
};

const FooterSection: React.FC<FooterSectionProps> = ({ title, links }) => {
    return (
        <div className="mb-4 w-full sm:mb-0 sm:w-auto">
            <h5 className="mb-2 text-lg font-bold text-white">{title}</h5>
            <div className="mb-2 w-[38px] border-b-2 border-blue-500"></div>
            <ul>
                {links.map((link, index) => (
                    <LinkItem key={index} text={link.text} link={link.link} />
                ))}
            </ul>
        </div>
    );
}

const LinkItem: React.FC<LinkItemProps> = ({ text, link }) => {
    return (
        <li className='my-2'>
            <Link to={link} className="hover:underline">{text}</Link>
        </li>
    );
}

const productLinks: LinkItemProps[] = [
    { text: 'General Liability', link: '#' },
    { text: 'Property', link: '#' },
    { text: 'Umbrella', link: '#' },
    { text: 'Workers Compenstation', link: '#' },
    { text: 'Cyber', link: '#' },
    { text: 'Professional Liability', link: '#' },
    { text: 'Liquor Liability', link: '#' },
    { text: 'Media Liability', link: '#' },
    { text: 'Business Auto', link: '#' },
];

const industryLinks: LinkItemProps[] = [
    { text: 'Sports', link: '#' },
    { text: 'Media', link: '#' },
    { text: 'Entertainment', link: '#' },
    { text: 'E-commerce', link: '#' },
    { text: 'Technology', link: '#' },
    { text: 'Professional Services', link: '#' },
    { text: 'Emerging Market', link: '#' },
];

const companyLinks: LinkItemProps[] = [
    { text: 'Careers', link: '#' },
    { text: 'Privacy Policy', link: '#' },
    { text: 'Terms & Conditions', link: '#' },
];

export default Footer;