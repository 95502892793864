import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSwipeable as useSwipeableHook, SwipeableHandlers } from 'react-swipeable';

import entertainmentImg from '../../assets/img/entertainment_service.png'
import sportsImg from '../../assets/img/sports_service.png'
import mediaImg from '../../assets/img/media_service.png'
import ecommerceImg from '../../assets/img/e-commerce_service.png'
import techImg from '../../assets/img/tech_service.png'
import professionalImg from '../../assets/img/professional_service.png'
import emergingImg from '../../assets/img/emerging_service.png'

const EMAIL_ICON_SRC = require('../../assets/icons/email.svg').default;
const ARROW_RIGHT_ICON_SRC = require('../../assets/icons/arrow_right.svg').default;
const ARROW_LEFT_ICON_SRC = require('../../assets/icons/arrow_left.svg').default;

const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};

const IndustriesSection: React.FC = () => {
    const [[page, direction], setPage] = useState([0, 0]);

    const paginate = (newDirection: number) => {
        setPage([page + newDirection, newDirection]);
    };

    const currentIndex = ((page % industries.length) + industries.length) % industries.length;

    const handlers: SwipeableHandlers = useSwipeableHook({
        onSwipedLeft: () => paginate(1),
        onSwipedRight: () => paginate(-1),
        trackMouse: true
    });

    return (
        <div className="flex flex-col overflow-hidden px-4 py-6 sm:px-10 sm:py-8">
            <h2 className="mb-4 text-center text-2xl sm:mb-6 sm:text-5xl">Industries We Service</h2>
            <div className="relative mt-8 flex justify-center sm:mt-20" {...handlers}>
                <AnimatePresence initial={false} custom={direction}>
                    <motion.div
                        key={page}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{
                            x: { type: "spring", stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 }
                        }}
                        className="absolute flex w-full flex-col sm:max-w-[1000px] sm:flex-row sm:justify-between"
                    >
                        <div className="w-full px-4 sm:w-1/2 sm:pr-16">
                            <h3 className="mb-4 text-xl sm:mb-8 sm:text-3xl">{industries[currentIndex].title}</h3>
                            <p className="text-base leading-7 text-neutral-600 sm:text-xl">{industries[currentIndex].description}</p>
                        </div>
                        <div className='relative hidden sm:block'>
                            <div className='absolute left-2 top-2 h-[460px] w-[420px] rounded-xl bg-black'></div>
                            <img
                                src={industries[currentIndex].image}
                                alt={industries[currentIndex].title}
                                className="relative h-[460px] w-[420px] rounded-xl object-cover"
                            />
                        </div>
                    </motion.div>
                </AnimatePresence>
                <button
                    onClick={() => paginate(-1)}
                    className="absolute left-10 top-[200px] z-50 hidden bg-transparent sm:block"
                >
                    <img src={ARROW_LEFT_ICON_SRC} alt="Arrow Icon" className="h-[60px]" />
                </button>
                <button
                    onClick={() => paginate(1)}
                    className="absolute right-10 top-[200px] z-10 hidden bg-transparent sm:block"
                >
                    <img src={ARROW_RIGHT_ICON_SRC} alt="Arrow Icon" className="h-[60px]" />
                </button>
            </div>
            <div className="mt-[380px] flex items-center justify-center sm:mt-[500px]">
                {industries.map((_, index) => (
                    <div
                        key={index}
                        className={`rounded-full mx-1 ${index === currentIndex ? 'bg-black h-3 w-3 sm:h-4 sm:w-4' : 'bg-neutral-200 h-2 w-2 sm:h-3 sm:w-3'
                            }`}
                    />
                ))}
            </div>
            <div className="my-8 flex flex-col items-center text-center sm:my-10">
                <p className="mb-2 text-xl font-bold text-neutral-800 sm:text-2xl">Don't see your industry?</p>
                <p className="text-sm text-neutral-500 sm:text-base">Write us an email and get recommendations for your industry</p>
                <button
                    onClick={() => window.location.href = 'mailto:support@kloutgroup.com'}
                    className="mt-6 flex items-center rounded-xl bg-black px-4 py-2 text-sm text-white sm:mt-8 sm:px-6 sm:py-3 sm:text-base">
                    Email us
                    <img src={EMAIL_ICON_SRC} alt="Arrow Icon" className="ml-2 h-[16px] sm:h-[26px]" />
                </button>
            </div>
        </div>
    );
};


export default IndustriesSection;

const industries = [
    {
        title: 'Sports',
        description: 'Sports organizations and athletes face high-stakes environments with physical risks and financial liabilities. Our specialized insurance covers teams, leagues, and athletes, addressing injury protection, liability, event insurance, and more. We develop customized plans for maximum security, letting you focus on achieving excellence in sports without worrying about potential setbacks.',
        image: sportsImg,
    },
    {
        title: 'Media',
        description: 'In the fast-paced media industry, businesses face unique risks such as liability issues and intellectual property disputes. Our brokerage offers specialized insurance solutions to protect media companies, ensuring you can focus on content creation without worrying about legal or financial setbacks. With tailored coverage and expert guidance, we help media businesses navigate their industry\'s complexities confidently and securely.',
        image: mediaImg,
    },
    {
        title: 'Entertainment',
        description: 'The entertainment industry, from film production to live events, requires substantial investment and careful planning, making insurance essential. Our solutions offer protection against event cancellations, equipment damage, and liability claims. We understand this dynamic field\'s intricacies, providing customized insurance plans to safeguard your projects and investments, allowing your creative visions to thrive.',
        image: entertainmentImg,
    },
    {
        title: 'E-Commerce',
        description: 'The booming e-commerce sector faces risks like cyber threats and operational disruptions. Our brokerage offers tailored insurance solutions to protect your online business, including cyber liability, business interruption, and product liability coverage. With our expertise and innovative platform, securing the right insurance is efficient and straightforward, empowering your e-commerce business to thrive.',
        image: ecommerceImg,
    },
    {
        title: 'Technology',
        description: 'The technology sector faces rapid innovation and significant risks, from intellectual property to cybersecurity threats. Our insurance solutions are tailored for tech companies, covering data breaches, product liability, and more. With customized plans, we help tech businesses focus on innovation and growth, providing comprehensive coverage and expert support to navigate the industry\'s complexities securely.',
        image: techImg,
    },
    {
        title: 'Emerging Markets',
        description: 'Emerging markets offer unique opportunities and challenges, with diverse risks such as political instability and supply chain disruptions. Our specialized insurance solutions protect businesses in these dynamic environments, offering tailored plans that ensure security and flexibility. With Klout, you can confidently expand into new regions, safeguarding your investments and operations.',
        image: emergingImg,
    },
    {
        title: 'Proffesional Services',
        description: 'Professionals in talent management, agencies, legal, and accounting fields face reputational and financial risks. Our insurance solutions cover errors and omissions, liability, and business interruption, providing comprehensive protection for your practice. We offer customized plans to mitigate risks, allowing you to focus on delivering exceptional service to your clients.',
        image: professionalImg,
    },
];

function useSwipeable(arg0: { onSwipedLeft: () => void; onSwipedRight: () => void; trackMouse: boolean; }) {
    throw new Error('Function not implemented.');
}
