import React, { useEffect, useState } from 'react';

const ARROW_ICON_SRC = require('../assets/icons/arrow-up.svg').default;

const ScrollToTop: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 800) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            className={`fixed bottom-8 right-8 z-50 w-12 h-12 border bg-white rounded-full hover:opacity-100 flex items-center justify-center cursor-pointer transition-opacity duration-300 ${isVisible ? 'opacity-60' : 'opacity-0 hover:opacity-100'}`}
            onClick={scrollToTop}
        >
            <img src={ARROW_ICON_SRC} alt="Scroll to top" className="h-6 w-6" />
        </div>
    );
};

export default ScrollToTop;
