import { motion } from 'framer-motion';

export default function Product() {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="flex min-h-screen items-center justify-center"
        >
            <h1 className="text-4xl font-bold text-primary">Product Page</h1>
        </motion.div>
    )
}
