export const smoothScrollTo = (
    endX: number,
    endY: number,
    duration: number
) => {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = performance.now();

    const easeInOutQuad = (
        time: number,
        from: number,
        distance: number,
        duration: number
    ) => {
        time /= duration / 2;
        if (time < 1) return (distance / 2) * time * time + from;
        time--;
        return (-distance / 2) * (time * (time - 2) - 1) + from;
    };

    const animateScroll = (currentTime: number) => {
        const timeElapsed = currentTime - startTime;
        const nextX = easeInOutQuad(timeElapsed, startX, distanceX, duration);
        const nextY = easeInOutQuad(timeElapsed, startY, distanceY, duration);
        window.scrollTo(nextX, nextY);
        if (timeElapsed < duration) {
            requestAnimationFrame(animateScroll);
        }
    };

    requestAnimationFrame(animateScroll);
};
