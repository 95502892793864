import { useEffect } from 'react';
import { getIcon } from '../../components/utils/iconLoader';

const companiesLogos = [
    'yahoo', 'forbes', 'techcrunch', 'business_insider', 'bloomberg'
];

export const Carousel = () => {
    const handleMouseDown = (e: MouseEvent) => {
        const track = e.currentTarget as HTMLDivElement;
        track.style.animationPlayState = 'paused';
        track.dataset.mouseDownAt = e.clientX.toString();
    };

    const handleMouseMove = (e: MouseEvent) => {
        const track = e.currentTarget as HTMLDivElement;
        if (track.dataset.mouseDownAt === undefined) return;

        const mouseDelta = parseFloat(track.dataset.mouseDownAt) - e.clientX;
        const maxDelta = window.innerWidth / 2;

        const percentage = (mouseDelta / maxDelta) * -100;
        const nextPercentage = Math.min(Math.max(parseFloat(track.dataset.prevPercentage || '0') + percentage, -100), 0);

        track.dataset.percentage = nextPercentage.toString();
        track.style.transform = `translate(${nextPercentage}%, -50%)`;
    };

    const handleMouseUp = (e: MouseEvent) => {
        const track = e.currentTarget as HTMLDivElement;
        track.dataset.mouseDownAt = undefined;
        track.dataset.prevPercentage = track.dataset.percentage || '0';
        track.style.animationPlayState = 'running';
    };

    useEffect(() => {
        const track = document.querySelector('.carousel-track') as HTMLDivElement | null;
        if (track) {
            track.addEventListener('mousedown', handleMouseDown as EventListener);
            track.addEventListener('mousemove', handleMouseMove as EventListener);
            track.addEventListener('mouseup', handleMouseUp as EventListener);

            return () => {
                track.removeEventListener('mousedown', handleMouseDown as EventListener);
                track.removeEventListener('mousemove', handleMouseMove as EventListener);
                track.removeEventListener('mouseup', handleMouseUp as EventListener);
            };
        }
    }, []);

    return (
        <div className='relative w-full overflow-hidden'>
            <div className='flex w-[calc(260px*10)] animate-scroll' data-mouse-down-at="0" data-prev-percentage="0">
                {[...companiesLogos, ...companiesLogos, ...companiesLogos].map((logo, index) => (
                    <div key={index} className='flex w-[200px] flex-none items-center justify-center transition-transform duration-300 ease-in-out md:h-[220px] md:w-[250px]'>
                        <img src={getIcon(logo)} alt={logo} className='w-[120px] md:w-[160px]' />
                    </div>
                ))}
            </div>
        </div>
    );
};
