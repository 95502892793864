import React, { useState, useEffect } from 'react';
import { motion, AnimationControls, PanInfo } from 'framer-motion';
import { RefObject } from 'react';

interface CardProps {
    title: string;
    content: string;
    index: number;
    active: number;
    total: number;
}

const Card: React.FC<CardProps> = ({ title, content, index, active, total }) => {
    const isMobile = useIsMobile();

    if (isMobile) {
        const offset = (index - active + total) % total - Math.floor(total / 2);
        const isActive = offset === 0;
        const xOffset = offset * 80;
        const scale = isActive ? 1 : 0.9;
        const zIndex = isActive ? 1 : 0;
        const blur = isActive ? '' : 'blur(8px)';

        return (
            <motion.div
                className="absolute left-1/2 top-0 h-[360px] w-[80%] max-w-[300px] rounded-xl bg-[#F1F1F1] shadow-xl"
                style={{
                    x: `calc(-50% + ${xOffset}px)`,
                    scale: scale,
                    zIndex: zIndex,
                    backdropFilter: blur,
                    transition: 'all 0.5s ease-out',
                }}
            >
                <div className='flex h-[320px] flex-col rounded-xl border bg-white p-6'>
                    <h3 className="mb-4 text-center text-lg">
                        {title}
                    </h3>
                    <p className="flex-grow overflow-y-auto text-sm text-gray-600">
                        {content}
                    </p>
                </div>
            </motion.div>
        );
    }

    return (
        <div className="w-[33%] flex-shrink-0 p-4">
            <div className='h-[360px] rounded-lg bg-[#F1F1F1] shadow-inner'>
                <motion.div
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: "spring", stiffness: 300 }}
                    className="h-[320px] rounded-md border bg-white p-6 shadow-md">
                    <h3 className="mb-2 text-wrap px-6 text-center text-xl">
                        {title}
                    </h3>
                    <p className="w-[95%] text-wrap text-base tracking-tight text-[#606060]">
                        {content}
                    </p>
                </motion.div>
            </div>
        </div>
    );
};

interface UserCardsProps {
    trackControls: AnimationControls;
    handleHover: (e: React.MouseEvent<HTMLDivElement>) => void;
    sectionRef: RefObject<HTMLElement>;
}

export const UserCards: React.FC<UserCardsProps> = ({ trackControls, handleHover, sectionRef }) => {
    const [active, setActive] = useState(0);
    const isMobile = useIsMobile();

    const cards = [
        {
            title: 'Problem',
            content: `Klout, the "Amazon of Insurance," is a brokerage that embraces today's technology and processes, challenging the status quo. The insurance industry is fragmented, vast, and complicated, but it doesn't need to be. Filling out paper applications and waiting weeks for a quote shouldn't be the norm; this inefficiency is a result of the industry's technological archaicism.`
        },
        {
            title: 'Revolutionizing Customer Experience',
            content: `We aim to improve the customer experience in every way, allowing insurance to catch up to modern business practices through white-glove service, technological innovation, the utilization of artificial intelligence, and proprietary features.`
        },
        {
            title: 'Removing Obstacles in Insurance',
            content: `Klout is removing every obstacle in the underwriting, purchasing, and management of insurance. While we specialize in the sports, media, entertainment, e-commerce, technology, professional services, and emerging sectors, we are capable of serving clients in any industry.`
        },
        {
            title: 'Relationships Enhanced by Technology',
            content: `Klout is built on relationships and optimized by technology. We believe we can enhance client relationships through technology while continually providing personalized service. Technology is a crucial tool that enhances customer experiences and broker efficiencies, allowing us to spend more time with our clients.`
        },
    ];

    const handleDrag = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
        if (isMobile) {
            const newActive = (active - Math.sign(info.offset.x) + cards.length) % cards.length;
            setActive(newActive);
        }
    };

    return (
        <section ref={sectionRef} className="flex w-full flex-col items-center space-y-8 overflow-hidden border-b text-neutral-900 md:pb-6">
            <h2 className="px-8 text-center text-2xl sm:text-3xl">
                Klout:
                <br />
                The "Amazon of Insurance"
            </h2>
            <div className="relative h-[400px] w-full overflow-hidden">
                <motion.div
                    id="card-container"
                    className={`${isMobile ? 'w-full relative' : 'cursor-pointer flex mx-16 whitespace-nowrap'}`}
                    animate={trackControls}
                    onMouseMove={isMobile ? undefined : handleHover}
                    drag={isMobile ? "x" : undefined}
                    dragConstraints={{ left: 0, right: 0 }}
                    onDragEnd={handleDrag}
                >
                    {cards.map((card, index) => (
                        <Card
                            key={index}
                            title={card.title}
                            content={card.content}
                            index={index}
                            active={active}
                            total={cards.length}
                        />
                    ))}
                </motion.div>
            </div>
        </section>
    );
};

// Custom hook to check if the device is mobile
function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    return isMobile;
}