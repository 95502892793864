import React, { useState, useEffect, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const LOGO_SRC = require('../assets/klout_black_logo.svg').default;

const Header: React.FC = () => {
    const [visible, setVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const controlNavbar = () => {
        setVisible(window.scrollY <= lastScrollY);
        setLastScrollY(window.scrollY);
    };

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);
        return () => {
            window.removeEventListener('scroll', controlNavbar);
        };
    }, [lastScrollY]);

    return (
        <div
            className={`fixed left-0 top-0 z-50 w-full transition-all duration-300 hover:bg-white hover:bg-opacity-90 ${visible ? 'transform translate-y-0' : 'transform -translate-y-full'}`}
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(window.scrollY === 0)}
        >
            <div className="mx-auto px-8">
                <div className="flex h-16 items-center justify-between">
                    <Logo />
                    {/* <MenuButton toggleDrawer={toggleDrawer} isOpen={isOpen} /> */}
                    {/* <DrawerNav isOpen={isOpen} closeDrawer={() => setIsOpen(false)} /> */}
                    {/* <DesktopNav /> */}
                    {/* <AuthButtons /> */}
                </div>
            </div>
        </div>
    );
};

const Logo: React.FC = () => (
    <div className="flex flex-1 justify-center">
        <Link to="/">
            <img src={LOGO_SRC} alt="Logo" />
        </Link>
    </div>
);

interface MenuButtonProps {
    toggleDrawer: () => void;
    isOpen: boolean;
}

const MenuButton: React.FC<MenuButtonProps> = ({ toggleDrawer, isOpen }) => (
    <div className="flex flex-1 justify-end md:hidden">
        <div className="text-center">
            <button onClick={toggleDrawer} className="z-50 focus:outline-none">
                <motion.div initial={false} animate={isOpen ? "open" : "closed"} className="space-y-2">
                    <motion.span
                        variants={{
                            closed: { rotate: 0, y: 0 },
                            open: { rotate: 45, y: 10 },
                        }}
                        className="block h-0.5 w-8 bg-black"
                    />
                    <motion.span
                        variants={{
                            closed: { opacity: 1 },
                            open: { opacity: 0 },
                        }}
                        className="block h-0.5 w-8 bg-black"
                    />
                    <motion.span
                        variants={{
                            closed: { rotate: 0, y: 0 },
                            open: { rotate: -45, y: -10 },
                        }}
                        className="block h-0.5 w-8 bg-black"
                    />
                </motion.div>
            </button>
        </div>
    </div>
);

const DesktopNav: React.FC = () => (
    <div className="hidden flex-[2] justify-center md:flex">
        <div className="flex space-x-4">
            {["Products", "Industries", "Investors", "Press", "\"Ari\""].map((text, index) => (
                <Link key={index} to={`/${text.replace(/"/g, '').toLowerCase()}`} className="rounded-md px-3 py-2 text-sm font-medium text-neutral-600">
                    {text}
                </Link>
            ))}
        </div>
    </div>
);

const AuthButtons: React.FC = () => (
    <div className="hidden flex-1 items-center justify-end md:flex">
        {/* <Link to="/" className="rounded-md border bg-white px-4 py-2 text-sm font-medium text-neutral-900">
            Sign up
        </Link> */}
    </div>
);

interface DrawerNavProps {
    isOpen: boolean;
    closeDrawer: MouseEventHandler<HTMLAnchorElement>;
}

const DrawerNav: React.FC<DrawerNavProps> = ({ isOpen, closeDrawer }) => (
    <motion.div
        initial={{ x: '-100%' }}
        animate={{ x: isOpen ? 0 : '-100%' }}
        transition={{ type: 'linear', duration: 0.5 }}
        id="drawer-navigation"
        className="fixed left-0 top-0 z-40 h-screen w-64 overflow-y-auto bg-black p-4 transition-transform"
        aria-labelledby="drawer-navigation-label"
    >
        <nav className="flex flex-col space-y-4 p-4">
            {["Products", "Industries", "Investors", "Press", "\"Ari\""].map((text, index) => (
                <Link key={index} to={`/${text.replace(/"/g, '').toLowerCase()}`} onClick={closeDrawer} className="text-xl text-neutral-100">
                    {text}
                </Link>
            ))}
        </nav>
    </motion.div>
);

export default Header;
