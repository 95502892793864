import { motion } from 'framer-motion';
import { RefObject } from 'react';

interface SectionProps {
    className: string;
    imgSrc: string;
    imgAlt: string;
    titleText: string[];
    contentText: string[];
    sectionRef: RefObject<HTMLElement>;
    inView: boolean;
    isTitleRightAligned?: boolean;
}

export const Section: React.FC<SectionProps> = ({
    className,
    imgSrc,
    imgAlt,
    titleText,
    contentText,
    sectionRef,
    inView,
    isTitleRightAligned = false
}) => {
    return (
        <motion.section ref={sectionRef} className={className}>
            <motion.img
                src={imgSrc}
                alt={imgAlt}
                initial={{ opacity: 0, y: 20 }}
                animate={inView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1, ease: 'easeOut' }}
            />
            <motion.div
                className={`z-10 flex ${isTitleRightAligned ? 'text-right flex-col items-center flex-1' : 'flex-1 justify-center'}`}
                initial={{ opacity: 0, x: 20 }}
                animate={inView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 1, ease: 'easeOut' }}
            >
                <div className={`flex flex-col px-4 mb-10 sm:mb-0 ${isTitleRightAligned ? 'text-center sm:text-right' : 'text-center sm:text-left'} max-w-lg`}>
                    <h2 className={`text-2xl text-neutral-900 sm:text-4xl`}>
                        {titleText.map((line, index) => (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </h2>
                    <p className={`mt-2 text-[14px] font-[400] text-secondary-light sm:mt-4 sm:text-xl`}>
                        {contentText.map((line, index) => (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </p>
                </div>
            </motion.div>
        </motion.section>
    );
};
