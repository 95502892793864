const iconMap: Record<string, string> = {
    techcrunch: require('../../assets/techcrunch_logo.svg').default,
    forbes: require('../../assets/forbes_logo.svg').default,
    business_insider: require('../../assets/businessinsider_logo.svg').default,
    yahoo: require('../../assets/yahoo_logo.svg').default,
    bloomberg: require('../../assets/bloomberg_logo.svg').default,
};

export function getIcon(iconName: string): string {
    return iconMap[iconName] || iconMap['defaultIcon'];
}
