// import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import Home from './pages/Home';
import Product from './pages/Product';
import Industry from './pages/Industry';
import Investors from './pages/Investors';
import Press from './pages/Press';
import Ari from './pages/Ari';
import NotFound from './pages/NotFound';

// const isAuthenticated = () => {
//     return localStorage.getItem('authToken') !== null;
// };

// const ProtectedRoute: React.FC<{ children: React.ReactElement }> = ({
//     children,
// }) => {
//     const location = useLocation();

//     if (!isAuthenticated()) {
//         return <Navigate to="/login" state={{ from: location }} />;
//     }

//     return children;
// };

const routes = [
    { path: '/', element: <Home /> },
    { path: '/products', element: <Product /> },
    { path: '/industries', element: <Industry /> },
    { path: '/investors', element: <Investors /> },
    { path: '/press', element: <Press /> },
    { path: '/ari', element: <Ari /> },
    { path: '*', element: <NotFound /> },
];

export default routes;
