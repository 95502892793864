// src/pages/NotFound.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { AiOutlineWarning } from 'react-icons/ai';

const NotFound: React.FC = () => {
    return (
        <div className="flex min-h-screen flex-col items-center justify-center bg-white text-black">
            <motion.div
                initial={{ scale: 0 }}
                animate={{ rotate: 360, scale: 1 }}
                transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                }}
            >
                <AiOutlineWarning size={100} />
            </motion.div>
            <h1 className="mb-4 mt-4 text-4xl font-bold">Page Not Found</h1>
            <Link to="/" className="border-b-2 border-white text-xl">
                <motion.div
                    initial={{ opacity: 0.5 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 0.8,
                        repeat: Infinity,
                        repeatType: "reverse",
                    }}
                >
                    Go to Home Page
                </motion.div>
            </Link>
        </div>
    );
};

export default NotFound;
